/* Warning: please do not statically import any dependencies here except for static assets like icons */
/* eslint-disable import/no-anonymous-default-export */
import { HelpIcon } from '@panwds/icons'
import { _T } from './i18n'
import type { NavParams, StoreState } from '@sparky/framework'

export default ({ vars, sparkyFramework: fx }: NavParams) => {
  return (
    fx.getFrameworkVar<boolean>('use_new_left_nav') && {
      actions: [
        {
          key: 'coveo',
          title: _T('Help'),
          navComponent: () => import(/* webpackPreload: true */ './NavHelp'),
          mapStateToProps: (state: StoreState) => {
            const coveo = state.coveo
            const predefinedToken = vars?.token as string | undefined
            const showMetaDataDropdown: boolean =
              fx.hasTsgSupport() &&
              fx.hasMicroApp('aiops-for-ngfw') &&
              Boolean(state.auth?.instances?.isRunningStatus('strata_insights'))
            return { predefinedToken, showMetaDataDropdown, coveo }
          },
          noRBACheck: true,
          icon: HelpIcon,
          avail: () => true,
          callAvail: 'override',
          _override_existing: true,
        },
      ],
    }
  )
}
